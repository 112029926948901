import React, { useEffect, useRef, useState } from "react";
import SidebarDireita from "../Sidebar-Direita/Sidebar-Direita";
import camera from "./Assets/camera2.png";
import livro from "./Assets/livros.png";
import notas from "./Assets/notas.png";
import AnimatedPixels from "../AnimatedPixels/AnimatedPixels";
import ImageGrid from "./grid";
import Edition from "./edition"; // Importando o novo componente
import images from "./Assets/imageImports"; // Importando as imagens dinamicamente
import { useNavigate } from "react-router-dom";
import titles from "./titles";
function Vertical2(props) {
  const elementRef = useRef(null);
  const sliderRef = useRef(null);
  const [currentEdition, setCurrentEdition] = useState(null);
  const [showImageGrid, setShowImageGrid] = useState(true);
  const [sliderIndex, setSliderIndex] = useState(0);
  const navigate = useNavigate(); // Hook de navegação

  // Função auxiliar para gerar o array de imagens
  const generateImagesArray = (editionNumber, totalImages) => {
    return Array.from({ length: totalImages }, (_, index) => ({
      small: images[`edit${editionNumber}_${index + 1}_small`],
      large: images[`edit${editionNumber}_${index + 1}_large`],
    }));
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(-${sliderIndex * 100}%)`;
    }
  }, [sliderIndex]);

  useEffect(() => {
    if (props.placeOn === "pagina-vertical-1") {
      setTimeout(() => {
        if (elementRef.current) {
          elementRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 1500);
    }
  }, [props.placeOn]);

  const handleViewMore = (edition) => {
    setShowImageGrid(false);
    setCurrentEdition(edition);
  };

  const handleBack = () => {
    setShowImageGrid(true);
    setCurrentEdition(null);
  };

  const editionData = {
    1: {
      images: generateImagesArray(1, 39),
      titles: titles[1],
      description: `
      O Setor de Extensão da Escola de Comunicação da UFRJ vem firmando esforços no propósito de 
      objetivar projetos de interação entre a Universidade e a sociedade, gerando assim uma interface 
      mais rica entre os saberes acadêmicos e o saber popular, que vem do exercício da vida e da prática 
      do querer. A produção de eventos de arte e cultura tem sido uma constante em nossas escolhas, por 
      acreditarmos ser a arte uma forma de expressão das inquietações que impulsionam o ser humano nas suas 
      mais sublimes manifestações. Longe do saudosismo ou mesmo da resistência às novas propostas contemporâneas, 
      o projeto procurou homenagear a fotografia como elemento de resgate da cultura plural dos salões, com base 
      no fortalecimento dos direitos da comunicação imagética. Dessa forma, exalta-se o compromisso social e a beleza, 
      através do olhar, que denuncia e se volta esteticamente para a terra e para as subjetividades: Eu sou Brasil, tema 
      escolhido para a nossa primeira Documenta. Para todos os sonhadores culturais e visuais, que participaram conosco 
      dessa jornada, os nossos sinceros agradecimentos.
    `,
      signatures: [
        { title: "Reitor da UFRJ", name: "Aloiso Teixeira" },
        {
          title: "Pró-Reitora de Extensão da UFRJ",
          name: "Laura Tavares Ribeiro Soares",
        },
        {
          title: "Superintendente Geral de Extensão",
          name: "Isabel Cristina Alencar de Azevedo",
        },
        { title: "Decano do CFCH", name: "Marcelo Macedo Correia e Castro" },
        { title: "Diretora da Escola de Comunicação", name: "Ivana Bentes" },
        {
          title: "Coordenadora do Setor de Extensão da ECO",
          name: "Wanelytcha Simonini",
        },
        { title: "Supervisora", name: "Mercia Roseli Pessoa" },
        {
          title: "Produção",
          name: "Dino Carvalho, Gleise Dutra, Nana Veríssimo, Louise Gonzaga Alves Palma, Luiz Henrique Guimarães, Raffaele Enrico Calandro",
        },
        { title: "Design gráfico", name: "Ângela Cardoso Braga" },
        { title: "Curadoria", name: "Wanelytcha Simonini" },
        {
          title: "Jurados",
          name: "Antônio Carlos Kern, Dante Gastaldoni, João Roberto Ripper",
        },
      ],
    },
    2: {
      images: generateImagesArray(2, 39),
      titles: titles[2],
      description: `
      Comemoração aos 170 anos da fotografia. A fotografia nasceu na manhã do dia 19 de agosto de 1839
      , quando Daguerre apresentou seu invento na Academia de Ciências e Artes de Paris. Desde então, a data 
      celebra o Dia Mundial da Fotografia, essa velha senhora que está prestes a completar 170 anos entre nós.
      E como tantas damas de outrora, a fotografia também subtraiu alguns anos de sua verdadeira idade, escondendo 
      de seus admiradores que havia nascido em 1826, ano em que Josephore Nièpce aprisionou em uma placa metálica a 
      sua célebre Vista da Janela. A heliografia (assim ele batizou seu invento) foi obtida após oito horas de exposição 
      à luz do sol, através de uma câmera localizada no sótão de sua casa de campo em Saint-Loup-de-Varennes e, desde então, 
      essa "herança genética" manifestou-se de modo permanente, fazendo com que a fotografia fosse metaforicamente identificada 
      como "uma janela para o mundo", este, não por acaso, o slogan da centenária National Geographic, a mais antiga revista fotográfica
       em circulação no planeta.
    `,
      signatures: [
        { title: "Reitor da UFRJ", name: "Aloiso Teixeira" },
        {
          title: "Pró-Reitora de Extensão da UFRJ",
          name: "Laura Tavares Ribeiro Soares",
        },
        {
          title: "CENTRO DE FILOSOFIA E CIÊNCIAS HUMANAS - Decano",
          name: "Marcelo Macedo Corrêa e Castro",
        },
        { title: "ESCOLA DE COMUNICAÇÃO - Direção", name: "Ivana Bentes" },
        { title: "ASSESSORIA DE IMPRENSA DA ECO", name: "Elisabeth Cerqueira" },
        { title: "CURADORIA DO ECOFOTO", name: "Dante Gastaldoni" },
        { title: "EXTENSÃO DA ECO - Coordenação", name: "Wanelytcha Simonini" },
        { title: "Supervisão", name: "Mercia Pessoa" },
        { title: "Produção Executiva", name: "Dino de Carvalho" },
        { title: "Programação Visual", name: "Fábio Portugal" },
        { title: "Produção Cultural", name: "Luiz Machado" },
        {
          title: "Assistentes de Produção",
          name: "Pedro Mansur, Gleise Nana, Luiz Henrique Guimarães, Carolina Calcavecchia",
        },
      ],
    },
    3: {
      images: generateImagesArray(3, 39),
      titles: titles[3],
      description: `
      O Setor de Extensão da Escola de Comunicação da UFRJ vem firmando esforços no propósito de objetivar projetos
       de interação entre a Universidade e a sociedade, gerando assim uma interface mais rica entre os saberes acadêmicos 
       e o saber popular, que vem do exercício da vida e da prática do querer.
A produção de eventos de arte e cultura tem sido uma constante em nossas escolhas, por acreditarmos ser a arte uma forma de 
expressão das inquietações que impulsionam o ser humano nas suas mais sublimes manifestações.
Longe do saudosismo ou mesmo da resistência às novas propostas contemporâneas, o projeto procurou homenagear a fotografia como
 elemento de resgate da cultura plural dos salões, com base no fortalecimento dos direitos da comunicação imagética. Dessa forma, 
 exalta-se o compromisso social e a beleza, através do olhar, que denuncia e se volta esteticamente para a terra e para as subjetividades:
  "Eu sou Brasil", tema escolhido para a nossa primeira Documenta.
Para todos os sonhadores culturais e visuais, que participaram conosco dessa jornada, os nossos sinceros agradecimentos.
    `,
      signatures: [
        { title: "Reitor da UFRJ", name: "Aloiso Teixeira" },
        {
          title: "Pró-Reitora de Extensão da UFRJ",
          name: "Laura Tavares Ribeiro Soares",
        },
        {
          title: "Superintendente Geral de Extensão",
          name: "Isabel Cristina Alencar de Azevedo",
        },
        { title: "Decano do CFCH", name: "Marcelo Macedo Corrêa e Castro" },
        { title: "Diretora da Escola de Comunicação", name: "Ivana Bentes" },
        {
          title: "Coordenadora do Setor de Extensão da ECO",
          name: "Wanelytcha Simonini",
        },
        { title: "Supervisora", name: "Mercia Roseli Pessoa" },
        {
          title: "Produção",
          name: "Dino de Carvalho,Dino Carvalho, Gleise Dutra Nana Veríssimo, Louise Gonzaga Alves Palma, Luiz Henrique Guimarães, Raffaele Enrico Calandro",
        },
        { title: "Design Gráfico", name: "Ângela Cardoso Braga" },
        { title: "Curadoria", name: "Wanelytcha Simonini" },
        {
          title: "Assistentes de Produção",
          name: "Pedro Mansur, Gleise Nana, Luiz Henrique Guimarães, Carolina Calcavecchia",
        },
        {
          title: "Jurados",
          name: "Antônio Carlos Kern, Dante Gastaldoni, João Roberto Ripper",
        },
      ],
    },
    4: {
      images: generateImagesArray(4, 20),
      titles: titles[4],
      description: `
      Utopias possíveis: transmutação do ordinário em maravilhoso
Comecei a prestar atenção no quanto este ato de escovar meus dentes tornou-se rotineiro, 
um comportamento inconsciente se comparado com meus primeiros esforços em realizá-lo quando criança.
 Comecei a suspeitar que 99% de minha vida diária era simplesmente rotinizada e despercebida; que meu 
 pensamento estava sempre em outro lugar qualquer: e que centenas de sinais que meu corpo enviava a cada
  minuto eram ignorados. Supus também que a maioria das pessoas era como eu nesse caso.¹
Domingo passado, sentado num banco da sala de desembarque do aeroporto, enquanto aguardava um voo chegar, 
acompanho uma criança, que deveria ter por volta de um ano de idade, correr de um lado para o outro, com 
a mãe logo atrás a protegendo. O menino gargalha sem parar. Com as pernas ainda trêmulas de quem dá os 
primeiros passos, o menino corre, cambaleando e rindo, se divertindo com o próprio ato de, perna após 
perna, andar por conta própria; utopias possíveis.
Talvez seja isso o que de melhor as crianças tenham a nos ensinar. A inocência de desbravar um mundo novo 
(mesmo que repetido) a cada instante traz a esses pequenos humanos cheios de humanidade sorrisos inabaláveis 
e olhos invariavelmente brilhantes. Cada ínfimo movimento do viver preenche totalmente as crianças de alegria
 e regozijo. Pelo olhar delas a existência adquire cores vivas e interessantes, segundo a segundo. 
    `,
      signatures: [
        {
          title: "Reitor da UFRJ",
          name: "Prof. Carlos Antônio Levi da Conceição",
        },
        {
          title: "Pró-Reitora de Extensão da UFRJ",
          name: "Prof. Pablo Cesar Benetti",
        },
        { title: "Direção", name: "Amaury Fernandés da Silva Junior" },
        { title: "Vice-Direção", name: "Cristina Rego Monteiro da Luz" },
        {
          title: "Assessoria de Imprensa da ECO",
          name: "Elizabete de Cerqueira, Maria Cecília Castro",
        },
        { title: "Curadoria do Ecofoto", name: "Davi Pereira" },
        {
          title: "Extensão da ECO",
          name: "Aline Portilho, Letícia Grativol (Substituta)",
        },
        {
          title: "Núcleo de Relações Institucionais",
          name: "Barbara Tavela, João C. de Andrade Neto",
        },
        {
          title: "Núcleo de Eventos e Projetos Culturais",
          name: "Fabiane Marcondes",
        },
        {
          title: "Núcleo de Comunicação",
          name: "Valdirene Martos (Programadora visual), Henrique Genovesi (Editor)",
        },
      ],
    },
    5: {
      images: generateImagesArray(5, 20),
      titles: titles[5],
      description: `
      Em seu desdobramento, o 5° ECOFOTO objetiva sedimentar o papel da fotografia enquanto a arte de capturar e eternizar o próprio tempo,
       valorizando a formação de profissionais de comunicação e artes visuais que se dedicam à arte de fotografar. Refletir sobre fotografia
        não implica simplesmente a discussão acerca de um certo tipo de imagem ou sobre um sistema de trocas simbólicas. Essa reflexão requer
         muito mais, pois desde a sua criação, a fotografia vem mostrando ser um veículo de conformação da realidade em um processo de montagem
          e seleção, no qual um universo pode se mostrar semelhante e diferente ao mesmo tempo.
A fotografia, imagem de múltiplos significados que se sujeita, por sua própria natureza, a enfoques diferenciados, 
pode ser ainda entendida a partir de visões particulares de micro ou macro análises.
Assim sendo, a justificativa para o incentivo de um evento que busca o entendimento e a prática dessa especificidade 
do olhar vem ganhando espaço no calendário de atividades culturais realizadas pela Extensão da Escola de Comunicação da 
UFRJ, pelo firme propósito de incentivar a prática do modo de expansão de uma estratégia de consumo icônico que não conhece 
fronteiras ou barreiras e que acaba por determinar modalidades semelhante
    `,
      signatures: [
        { title: "Reitor da UFRJ", name: "Carlos Antônio Levi da Conceição" },
        {
          title: "Pró-Reitora de Extensão da UFRJ",
          name: "Pablo Cesar Benetti",
        },
        { title: "ESCOLA DE COMUNICAÇÃO - Direção", name: "Ivana Bentes" },
        { title: "ASSESSORIA DE IMPRENSA DA ECO", name: "Elisabeth Cerqueira" },
        { title: "CURADORIA DO ECOFOTO", name: "Dante Gastaldoni" },
        { title: "EXTENSÃO DA ECO - Coordenação", name: "Wanelytcha Simonini" },
        { title: "Produção Executiva", name: "Dino de Carvalho" },
        {
          title: "Programação Visual",
          name: "Fábio Portugal, Valdirene Martos",
        },
        {
          title: "Bolsistas",
          name: "Beatriz de Sá Monteiro de Barros, Thais Carvas Farias de Castro",
        },
        { title: "Apoio", name: "Sheila Camlot" },
        {
          title: "Administrador da ECO",
          name: "Paulo Cesar dos Santos Marinho",
        },
      ],
    },
    6: {
      images: generateImagesArray(6, 19),
      titles: titles[6],
      description: `
      Utopias possíveis: transmutação do ordinário em maravilhoso
Comecei a prestar atenção no quanto este ato de escovar meus dentes tornou-se rotineiro, 
um comportamento inconsciente se comparado com meus primeiros esforços em realizá-lo quando criança. 
Comecei a suspeitar que 99% de minha vida diária era simplesmente rotinizada e despercebida; que meu 
pensamento estava sempre em outro lugar qualquer: e que centenas de sinais que meu corpo enviava a cada 
minuto eram ignorados. Supus também que a maioria das pessoas era como eu nesse caso.¹
Domingo passado, sentado num banco da sala de desembarque do aeroporto, enquanto aguardava um voo chegar, 
acompanho uma criança, que deveria ter por volta de um ano de idade, correr de um lado para o outro, com 
a mãe logo atrás a protegendo. O menino gargalha sem parar. Com as pernas ainda trêmulas de quem dá os 
primeiros passos, o menino corre, cambaleando e rindo, se divertindo com o próprio ato de, perna após perna, 
andar por conta própria; utopias possíveis.
Talvez seja isso o que de melhor as crianças tenham a nos ensinar. A inocência de desbravar um mundo novo 
(mesmo que repetido) a cada instante traz a esses pequenos humanos cheios de humanidade sorrisos inabaláveis 
e olhos invariavelmente brilhantes. Cada ínfimo movimento
    `,
      signatures: [
        {
          title: "Reitor da UFRJ",
          name: "Prof. Carlos Antônio Levi da Conceição",
        },
        {
          title: "Pró-Reitora de Extensão da UFRJ",
          name: "Prof. Pablo Cesar Benetti",
        },
        { title: "Direção", name: "Amaury Fernandés da Silva Junior" },
        { title: "Vice-Direção", name: "Cristina Rego Monteiro da Luz" },
        {
          title: "ASSESSORIA DE IMPRENSA DA ECO",
          name: "Elisabeth Cerqueira, Maria Cecília Castro",
        },
        { title: "CURADORIA DO ECOFOTO", name: "Davi Pereira" },
        {
          title: "EXTENSÃO DA ECO - Coordenação",
          name: "Aline Portilho, Letícia Grativol (Substituta)",
        },
        {
          title: "Núcleo de Relações Institucionais",
          name: "Barbara Tavela, João C. de Andrade Neto",
        },
        {
          title: "Núcleo de Eventos e Projetos Culturais",
          name: "Fabiane Marcondes",
        },
        {
          title: "Núcleo de Comunicação",
          name: "Valdirene Martos (Programadora visual), Henrique Genovesi (Editor)",
        },
      ],
    },
    7: {
      images: generateImagesArray(7, 32),
      titles: titles[7],
      description: `
      Utopias possíveis: transmutação do ordinário em maravilhoso
Comecei a prestar atenção no quanto este ato de escovar meus dentes tornou-se rotineiro, 
um comportamento inconsciente se comparado com meus primeiros esforços em realizá-lo quando criança. 
Comecei a suspeitar que 99% de minha vida diária era simplesmente rotinizada e despercebida; que meu 
pensamento estava sempre em outro lugar qualquer: e que centenas de sinais que meu corpo enviava a cada 
minuto eram ignorados. Supus também que a maioria das pessoas era como eu nesse caso.¹
Domingo passado, sentado num banco da sala de desembarque do aeroporto, enquanto aguardava um voo chegar, 
acompanho uma criança, que deveria ter por volta de um ano de idade, correr de um lado para o outro, com 
a mãe logo atrás a protegendo. O menino gargalha sem parar. Com as pernas ainda trêmulas de quem dá os 
primeiros passos, o menino corre, cambaleando e rindo, se divertindo com o próprio ato de, perna após perna, 
andar por conta própria; utopias possíveis.
Talvez seja isso o que de melhor as crianças tenham a nos ensinar. A inocência de desbravar um mundo novo 
(mesmo que repetido) a cada instante traz a esses pequenos humanos cheios de humanidade sorrisos inabaláveis 
e olhos invariavelmente brilhantes. Cada ínfimo movimento
    `,
      signatures: [
        {
          title:
            "Coordenação-geral, Direção de Comunicação e de Tecnologia, e Curadoria",
          name: "Camila Damico Medina (Setor de Extensão - UFRJ)",
        },
        {
          title: "Coordenação-adjunta, Direção de Audiovisual e Curadoria",
          name: "Joyce Abbade (Central de Produção Multimídia - UFRJ)",
        },
        {
          title: "Plataforma Virtual",
          name: "Leonardo Calegaro, Beatriz Mota, Ronivaldo Domingues de Andrade, Luciana Merege de Assis",
        },
        { title: "Relações Comerciais", name: "Ana Carolina Calomino Navarro" },
        {
          title: "Audiovisual",
          name: "Danty Alves, Estela Ribeiro, Amanda RosaLaura Miele, Marcela Linhares, Guilherme Prado, Renata Esperança",
        },
      ],
    },
    8: {
      images: generateImagesArray(8, 78),
      titles: titles[8],
      description: `
      Enquanto evento de extensão universitária produzido há mais de dez anos pelo corpo técnico-administrativo da Escola de Comunicação, 
      o Ecofoto entendeu que para os cem anos da Universidade Federal do Rio de Janeiro era preciso se reinventar. A necessidade de se 
      redescobrir bateu à porta justamente pelas limitações operacionais constituídas durante a pandemia, que inviabilizou para o Setor 
      de Extensão diversos produtos culturais característicos do evento.
A fim de estimular técnicas fotográficas experimentais e o acolhimento de vozes insurgentes na fotografia, a ação de extensão se 
constituiu como espaço excepcional para troca de saberes através da parceria com pesquisadores da Universidade Federal do Pará e 
da Universidade Federal do Ceará. Com o objetivo de formar fotógrafos autores de todas as partes do território latino-americano, 
o encontro promovido buscou valorizar polissemias; os participantes ensaiaram fissuras sobre narrativas visuais convencionais que 
nos cercam durante o dia-a-dia. Ao projetar ficções alternativas, vislumbramos meios de nos conectar com o outro nunca antes 
pensados como possíveis, mas que eram latentes.
    `,
      signatures: [
        {
          title:
            "Coordenação-geral, Direção de Comunicação e de Tecnologia, e Curadoria",
          name: "Camila Damico Medina (Setor de Extensão - UFRJ)",
        },
        {
          title: "Coordenação-adjunta, Direção de Audiovisual e Curadoria",
          name: "Joyce Abbade (Central de Produção Multimídia - UFRJ)",
        },
        {
          title: "Plataforma Virtual",
          name: "Leonardo Calegaro, Beatriz Mota, Ronivaldo Domingues de Andrade, Luciana Merege de Assis",
        },
        { title: "Relações Comerciais", name: "Ana Carolina Calomino Navarro" },
        {
          title: "Audiovisual",
          name: "Danty Alves, Estela Ribeiro, Amanda RosaLaura Miele, Marcela Linhares, Guilherme Prado, Renata Esperança",
        },
      ],
    },
  };
  return (
    <div
      className={
        props.placeOn === "pagina-vertical-2"
          ? "vertical pagina-vertical-2 up"
          : "vertical pagina-vertical-2 down"
      }
    >
      <div className="vertical-left hide-on-mobile">
        <div className="menu-vertical-left">
          <a href="#historia">_Senta que lá vem a história</a>
          <a href="#edicoes-anteriores" onClick={handleBack}>
            _Edições Anteriores
          </a>
          <a href="#webdocumentario">_Webdocumentário</a>
        </div>
        <div
          className="seta-pro-topo"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });

            const scrollableElements = document.querySelectorAll("*");
            scrollableElements.forEach((element) => {
              if (element.scrollHeight > element.clientHeight) {
                element.scrollTo({ top: 0, behavior: "smooth" });
              }
            });
          }}
        ></div>
      </div>
      <div className="vertical-right">
        <div className="vertical-padding">
          {props.textinhos &&
            props.textinhos.map(
              (t, i) =>
                t.sectionId === 2 && (
                  <div key={i}>
                    <div
                      id={t.tagId}
                      style={{ color: "var(--roxo)" }}
                      className="vertical-title bg-branco"
                    >
                      {t.title}
                    </div>
                    <div>
                      {t.tagId === "historia" && (
                        <div className="images-align-mobile">
                          <img
                            alt="livros"
                            src={livro}
                            className="float-right vertical-logo"
                          />
                          <img
                            alt="bloco de notas"
                            src={notas}
                            className="float-left vertical-notas"
                          />
                        </div>
                      )}
                      <div
                        className="vertical-text"
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    </div>
                  </div>
                )
            )}

          <div
            id="edicoes-anteriores"
            style={{ color: "var(--roxo)" }}
            className="vertical-title bg-branco"
          >
            Edições Anteriores
          </div>
          {showImageGrid ? (
            <ImageGrid onViewMore={handleViewMore} />
          ) : (
            currentEdition && (
              <Edition
                edition={currentEdition}
                images={editionData[currentEdition].images}
                titles={editionData[currentEdition]?.titles} // Modificação aqui
                description={editionData[currentEdition].description}
                signatures={editionData[currentEdition].signatures}
                onBack={handleBack}
                showBackButton={true}
              />
            )
          )}

          <div
            id="webdocumentario"
            style={{ color: "#5c1c74", backgroundColor: "white" }}
            className="vertical-title"
          >
            Webdocumentário
          </div>
          <a
            href="http://www.ecofoto.eco.ufrj.br/base/Publish%20to%20web%20-%20Ecofoto/#Intro"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="camera"
              src={camera}
              className="float-left vertical-logo"
            />
          </a>
          <div
            style={{ marginTop: 100 }}
            className="seta-pro-topo-mobile"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });

              const scrollableElements = document.querySelectorAll("*");
              scrollableElements.forEach((element) => {
                if (element.scrollHeight > element.clientHeight) {
                  element.scrollTo({ top: 0, behavior: "smooth" });
                }
              });
            }}
          ></div>
        </div>
        <AnimatedPixels amount={100} />
      </div>
      <SidebarDireita bg="var(--rosa)" />
    </div>
  );
}

export default Vertical2;
