import "./Subnavs.scss";
import React from "react";

function Sub1(props) {
  let estilo = {
    left: "-45vw",
    transition: "all 1s",
  };
  props.subOn === "show-1"
    ? (estilo = { left: "5vw", transition: "all 1s" })
    : (estilo = { left: "-45vw", transition: "all 1s" });

  function setPlace(position) {
    props.scrollToSection("primeiro", "pagina-vertical-1", position);
    setTimeout(
      function () {
        props.setSecondaryIconColor("var(--verde)");
      }.bind(this),
      2300
    );
  }

  return (
    <div>
      {props.placeOn !== "pagina-vertical-1" && (
        <div
          className="dropdown drop-1"
          style={estilo}
          onMouseEnter={() => props.showSub("show-1")}
          onMouseLeave={() => props.showSub("show-0")}
        >
          <div
            className={
              "sub sub-rosa a" + props.bg.substring(6, props.bg.length - 1)
            }
          >
            <a href="#sobre-nos" onClick={() => setPlace("sobre-nos")}>
              <span> &gt; Sobre nós</span>
            </a>
            <a href="#o-ciclo" onClick={() => setPlace("o-ciclo")}>
              <span> &gt; Ciclo de criação</span>
            </a>
            <a href="#como-faz" onClick={() => setPlace("como-faz")}>
              <span> &gt; Como que faz para realizar tudo isso?</span>
            </a>
            <a href="#nossa-galera" onClick={() => setPlace("nossa-galera")}>
              <span> &gt; Nossa galera</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sub1;
