import "./Home.scss";
import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarDireita from "../../Components/Sidebar-Direita/Sidebar-Direita";
import SidebarDireitaIcons from "../../Components/Sidebar-Direita/Side-Direita-Icons";
import Section from "../../Components/Section/section";
import Vertical1 from "../../Components/Vertical/vertical1";
import Vertical2 from "../../Components/Vertical/vertical2";
import Vertical3 from "../../Components/Vertical/vertical3";
import Pesquisa from "../../Components/Vertical/Search-Page";

import "../../Components/Subnavs/Subnavs.scss";

import service from "../../service";

import { useState, useEffect } from "react";

function Home() {
  // const resumos = [
  //   "Leia mais por aqui nesta seção se você tá curiose para saber mais da gente, com funciona o projeto de extensão, como a gente se reuniu em torno do propósito de estimular a produção fotográfica nacional e mais uns detalhezinhos. Chega aqui!",
  //   "Você sabe que esse projeto realiza de tempos em tempos um evento pra quem é fissurade em fotografia mas nem sempre encontra meios para trocar uma ideia sobre o que tem vivido e para testar linguagens diferentes? Clica aqui pra saber mais desse parangolé chamado ciclo de criação.",
  //   "Nesse bangalô, a gente conta mais sobre como apoiar o projeto. Existem várias formas de você chegar junto e facilitar a nossa jornada de promover narrativas alternativas na fotografia contemporânea brasileira. Vem, clica aqui para ler mais sobre como chegar junto!",
  // ];
  const [placeOn, setPlaceOn] = useState("down-1");
  const [secondaryColor, setSecondaryColor] = useState("var(--rosa)");
  const [secondaryIconColor, setSecondaryIconColor] = useState("var(--rosa)");
  const [position, setPosition] = useState("sobre-nos");
  const [videoMenuHover] = useState(false);
  const [textinhos, setTexts] = useState(null);
  const [value, setValue] = useState(null);
  const [result, setResult] = useState(null);
  const [pesquisa, setPesquisa] = useState(false);

  useEffect(() => {
    service
      .getAllPosts()
      .then((response) => {
        setTexts(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (placeOn === "pags") {
      setTimeout(function () {
        document
          .getElementById("secao-pags")
          .scrollIntoView({ behavior: "smooth" });
      }, 300);
    } else if (placeOn === "down-1") {
      setSecondaryIconColor(secondaryColor);
    }
  }, [placeOn, secondaryColor]);

  function scrollToSection(sectionName, place, position) {
    document.getElementById(sectionName).scrollIntoView({ behavior: "smooth" });
    setPlaceOn(place);
    if (position !== undefined) {
      setPosition(position);
    }
  }

  function closeSearcher(e) {
    if (pesquisa && !(e.target.name === "searcher")) {
      setPesquisa(false);
    }
  }

  return (
    
    <div className="Home" onClick={closeSearcher}>
      {(placeOn !== "video" || videoMenuHover === true) && (
        <Sidebar
          scrollToSection={scrollToSection}
          setSecondaryIconColor={setSecondaryIconColor}
          setSecondaryColor={setSecondaryColor}
          placeOn={placeOn}
          setPlaceOn={setPlaceOn}
          bg={secondaryColor}
        />
      )}

      <div className="Paginas" id="paginas">

        <div className="Container" id="secao-pags">
          <div id="primeiro" style={{ height: "0" }}></div>
          <Section
            scrollToSection={scrollToSection}
            setSecondaryIconColor={setSecondaryIconColor}
            setSecondaryColor={setSecondaryColor}
            name="primeiro"
            bg="var(--verde)"
            letreiro="role"
            secondary="preto"
            textinhos={textinhos}
            setPosition={setPosition}
          />
          <div id="seg" style={{ height: "0" }}></div>
          <Section
            scrollToSection={scrollToSection}
            setSecondaryIconColor={setSecondaryIconColor}
            setSecondaryColor={setSecondaryColor}
            name="seg"
            bg="var(--rosa)"
            letreiro="ciclo"
            secondary="branco"
            textinhos={textinhos}
            setPosition={setPosition}
          />
          <div id="ter" style={{ height: "0" }}></div>
          <Section
            scrollToSection={scrollToSection}
            setSecondaryIconColor={setSecondaryIconColor}
            setSecondaryColor={setSecondaryColor}
            name="ter"
            bg="var(--azul)"
            letreiro="chega"
            secondary="preto"
            textinhos={textinhos}
            setPosition={setPosition}
          />
        </div>
      </div>

      {placeOn !== "video" && (
        <>
          <SidebarDireita bg={secondaryColor} />
          <SidebarDireitaIcons
            pesquisa={pesquisa}
            setPesquisa={setPesquisa}
            scrollToSection={scrollToSection}
            textinhos={textinhos}
            setPlaceOn={setPlaceOn}
            placeOn={placeOn}
            setPosition={setPosition}
            bg={secondaryIconColor}
            setResult={setResult}
            setValue={setValue}
            setSecondaryIconColor={setSecondaryIconColor}
          />
        </>
      )}

      <Vertical1
        scrollToSection={scrollToSection}
        textinhos={textinhos}
        placeOn={placeOn}
        setPosition={setPosition}
        setPlaceOn={setPlaceOn}
        position={position}
      />
      <Vertical2
        scrollToSection={scrollToSection}
        textinhos={textinhos}
        placeOn={placeOn}
        setPosition={setPosition}
        setPlaceOn={setPlaceOn}
        position={position}
      />
      <Vertical3
        scrollToSection={scrollToSection}
        textinhos={textinhos}
        placeOn={placeOn}
        setPosition={setPosition}
        setPlaceOn={setPlaceOn}
        position={position}
      />
      <Pesquisa
        result={result}
        setResult={setResult} // Passa a função setResult para o componente Pesquisa
        value={value}
        setValue={setValue}
        scrollToSection={scrollToSection}
        placeOn={placeOn}
        setPosition={setPosition}
        position={position}
        setSecondaryIconColor={setSecondaryIconColor}
      />
    </div>
  );
}

export default Home;
