import "./vertical.scss";
import React, { useEffect, useRef, useState } from "react";
import SidebarDireita from "../Sidebar-Direita/Sidebar-Direita";
import ufrjlogo from "./Assets/UFRJlogo.png";
import camera from "./Assets/Camera.png";
import tv from "./Assets/Tv2.png";
import galera from "./Assets/galera.png";
import ciclo from "./Assets/ciclo.png";
import seta from "./Assets/seta-baixo.png";
import AnimatedPixels from "../AnimatedPixels/AnimatedPixels";
import Edition from "./edition";
import images from "./Assets/imageImports";
import titles from "./titles";
import service from "../../service"; // Importar a função da API

function Vertical1(props) {
  const [currentEdition, setCurrentEdition] = useState(null);
  const [isEditionVisible, setIsEditionVisible] = useState(false);
  const [isInscriptionEnabled, setIsInscriptionEnabled] = useState(false); // Novo estado
  const elementRef = useRef(null);

  const generateImagesArray = (editionNumber, totalImages) => {
    return Array.from({ length: totalImages }, (_, index) => ({
      small: images[`edit${editionNumber}_${index + 1}_small`],
      large: images[`edit${editionNumber}_${index + 1}_large`],
    }));
  };

  useEffect(() => {
    const fetchInscriptionStatus = async () => {
      try {
        const data = await service.getToggleInscription(); // Chama a API
        console.log("Status de inscrição:", data); // Log para verificar a resposta
        setIsInscriptionEnabled(data.value); // Armazena o valor retornado
      } catch (error) {
        console.error("Erro ao obter status de inscrição:", error);
      }
    };
  
    fetchInscriptionStatus(); // Executa a função

    if (props.placeOn === "pagina-vertical-1") {
      setTimeout(() => {
        if (elementRef.current) {
          elementRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 1500);
    }
  }, [props.placeOn]);

  const toggleEditionVisibility = () => {
    setIsEditionVisible(!isEditionVisible);
  };

  const currentEditionData = {
    images: generateImagesArray(1, 39),
    titles: titles[1],
    description: `O Setor de Extensão da Escola de Comunicação da UFRJ vem firmando esforços no propósito de 
      objetivar projetos de interação entre a Universidade e a sociedade, gerando assim uma interface 
      mais rica entre os saberes acadêmicos e o saber popular, que vem do exercício da vida e da prática 
      do querer. A produção de eventos de arte e cultura tem sido uma constante em nossas escolhas, por 
      acreditarmos ser a arte uma forma de expressão das inquietações que impulsionam o ser humano nas suas 
      mais sublimes manifestações. Longe do saudosismo ou mesmo da resistência às novas propostas contemporâneas, 
      o projeto procurou homenagear a fotografia como elemento de resgate da cultura plural dos salões, com base 
      no fortalecimento dos direitos da comunicação imagética. Dessa forma, exalta-se o compromisso social e a beleza, 
      através do olhar, que denuncia e se volta esteticamente para a terra e para as subjetividades: Eu sou Brasil, tema 
      escolhido para a nossa primeira Documenta. Para todos os sonhadores culturais e visuais, que participaram conosco 
      dessa jornada, os nossos sinceros agradecimentos.`,
    signatures: [
      {
        title:
          "Coordenação-geral, Direção de Comunicação e de Tecnologia, e Curadoria",
        name: "Camila Damico Medina (Setor de Extensão - UFRJ)",
      },
      {
        title: "Coordenação-adjunta, Direção de Audiovisual e Curadoria",
        name: "Joyce Abbade (Central de Produção Multimídia - UFRJ)",
      },
      {
        title: "Plataforma Virtual",
        name: "Leonardo Calegaro, Beatriz Mota, Ronivaldo Domingues de Andrade, Luciana Merege de Assis",
      },
      { title: "Relações Comerciais", name: "Ana Carolina Calomino Navarro" },
      {
        title: "Audiovisual",
        name: "Danty Alves, Estela Ribeiro, Amanda RosaLaura Miele, Marcela Linhares, Guilherme Prado, Renata Esperança",
      },
    ],
  };

  return (
    <div
      className={
        props.placeOn === "pagina-vertical-1"
          ? "vertical pagina-vertical-1 up"
          : "vertical pagina-vertical-1 down"
      }
      ref={elementRef} // Certifique-se de que o ref está aqui para o scroll
    >
      <div className="area-seta-pra-baixo">
        <img
          src={seta}
          alt="seta para abaixar a tela"
          className="seta-pra-baixo"
        />
      </div>
      <div className="vertical-left hide-on-mobile">
        <div className="menu-vertical-left">
          <a href="#historia">_Sobre nós</a>
          <a href="#o-ciclo">_Ciclo de criação</a>
          <a href="#como-faz">_Como que faz pra realizar tudo isso?</a>
          <a href="#nossa-galera">_Nossa galera</a>
        </div>
        <div
          className="seta-pro-topo"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });

            const scrollableElements = document.querySelectorAll("*");
            scrollableElements.forEach((element) => {
              if (element.scrollHeight > element.clientHeight) {
                element.scrollTo({ top: 0, behavior: "smooth" });
              }
            });
          }}
        ></div>
      </div>
      <div className="vertical-right">
        <div className="vertical-padding">
          {props.textinhos &&
            props.textinhos.map((t, i) => {
              if (t.sectionId === 1) {
                return (
                  <div key={i}>
                    <div
                      id={t.tagId}
                      style={{ color: "var(--roxo)" }}
                      className="vertical-title bg-branco"
                    >
                      {t.title}
                    </div>
                    <div>
                      {t.tagId === "sobre-nos" && (
                        <img
                          alt="UFRJ"
                          src={ufrjlogo}
                          className="float-right vertical-logo"
                        />
                      )}
                      {t.tagId === "o-ciclo" && (
                        <img
                          alt="ciclo"
                          src={ciclo}
                          className="float-right vertical-tv"
                        />
                      )}
                      {t.tagId === "missoes" && (
                        <img
                          alt="Câmera fotográfica"
                          src={camera}
                          className="float-right vertical-camera"
                        />
                      )}
                      {t.tagId === "como-faz" && (
                        <img
                          alt="Televisão"
                          src={tv}
                          className="vertical-tv float-left"
                        />
                      )}
                      {t.tagId === "nossa-galera" && (
                        <img
                          alt="galera"
                          src={galera}
                          className="float-right vertical-logo"
                        />
                      )}
                      <div
                        className="vertical-text"
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />

                      {t.tagId === "o-ciclo" && (
                        <div>
                          <button
                            className="edicao"
                            onClick={toggleEditionVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            {isEditionVisible
                              ? "Ocultar Edição Atual"
                              : isInscriptionEnabled
                              ? "Edição Atual - Inscreva-se aqui!"
                              : "Edição Atual"}
                          </button>
                          {isEditionVisible && (
                            <>
                              {isInscriptionEnabled ? (
                                <div>
                                  <p
                                    style={{ marginTop: "30px", fontSize: "28px" }}
                                    className="vertical-text"
                                  >
                                    As inscrições para a edição atual estão abertas
                                  </p>
                                  <a
                                    href="https://google.com.br"
                                    style={{
                                      marginTop: "30px",
                                      fontSize: "24px",
                                      display: "inline-block",
                                    }}
                                    className="vertical-link"
                                    target="_blank"
                                  >
                                    Clique aqui para se inscrever
                                  </a>
                                </div>
                              ) : (
                                <Edition
                                  edition="Edição Atual"
                                  images={currentEditionData.images}
                                  description={currentEditionData.description}
                                  signatures={currentEditionData.signatures}
                                  showBackButton={false}
                                />
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          <div
            className="seta-pro-topo-mobile"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });

              const scrollableElements = document.querySelectorAll("*");
              scrollableElements.forEach((element) => {
                if (element.scrollHeight > element.clientHeight) {
                  element.scrollTo({ top: 0, behavior: "smooth" });
                }
              });
            }}
          ></div>
        </div>
        <AnimatedPixels amount={100} />
      </div>
      <SidebarDireita bg="var(--verde)" />
    </div>
  );
}

export default Vertical1;
